
















import { Component, Vue } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import ConnectMetamask from './ConnectMetamask.vue'

@Observer
@Component({
  components: {
    ConnectMetamask,
  },
})
export default class Staking extends Vue {
  wallet = walletStore
  openLink(url) {
    window.open(url, '_blank')
  }
  backToHome() {
    this.$router.push('/')
  }
}
